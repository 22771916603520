.Container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Section {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

/* Home.css */

.home_carousel {
  width: 100%;
  height: 818px;

  @media screen and (max-width: 1000px) {
    height: 565px;
  }
}

.home_carousel_img {
  position: absolute;
  margin: 0px auto;
  width: 100%;
  height: 818px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1000px) {
    height: 565px;
  }
}

.home_carousel_item {
  position: absolute;
  margin-top: 90px;
  padding: 0px 40px;
  height: 728px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 1000px) {
    margin-top: 48px;
    padding: 0 20px;
    gap: 30px;
    height: 515px;
  }
}

.carousel-item {
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  transition: opacity 1s ease;
}

.carousel-item :active {
  opacity: 1;
}

.carousel_button {
  width: fit-content;
  text-decoration: none;
  border: 1px solid #e50012;
  padding: 15px 30px;
  font-size: 16px;
  color: #e50012;
}

.home_about_wrap {
  height: 659px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.home_about_contents {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 70px;
  flex: 4;
  @media screen and (max-width: 1000px) {
    padding: 0px;
    justify-content: flex-start;
  }
}

.home_about_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  @media screen and (max-width: 1000px) {
    padding: 20px;
  }
}

.home_about_button {
  text-decoration: none;
  background-color: #e50012;
  color: white;
  padding: 10px 40px;
  font-size: 16px;
}

.home_about_bg {
  width: 100%;
  height: 100%;
  flex: 3;
  @media screen and (max-width: 1000px) {
    height: 400px;
    flex: auto;
  }
}

.home_about_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text_colume_box {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 1000px) {
    gap: 2px;
  }
}

.gap20 {
  gap: 20px;
  @media screen and (max-width: 1000px) {
    gap: 10px;
  }
}

.text_row_box {
  display: flex;
  flex-direction: row;
}

.home_bottom_wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 0;
}

.home_bottom_container {
  padding: 30px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_bottom_contents {
  display: flex;
  margin: 40px 0 0;
  gap: 20px;
  width: 100%;
  max-width: 1250px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    gap: 10px;
  }
}

.home_bottom_outline {
  flex: 1;
  border: 1px solid #e50012;
}

.home_bottom_content_text_box {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  background-color: #fff;
}

/* intro.css */
.header_box {
  height: 475px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.header_bg_img {
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 475px;
  background-image: url(../assets/about_background.png);
  z-index: 0;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.about_text_table {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
}

.header_text_box {
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 475px;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  padding: 0 15px;
}

.path_wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.path_box {
  width: 100%;
  max-width: 1250px;
  height: 50px;
  margin-top: 30px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
}

.path_item {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 5px;
}

.about_contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 1250px;
  gap: 60px;
  @media screen and (max-width: 1000px) {
    padding: 0;
  }
}

.about_content_title {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0px 15px;
  margin-top: 150px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0 40px;
  }
}

.card_row {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  max-width: 1250px;
  padding: 0px 15px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
}

.card_row_reverse {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  align-items: center;
  max-width: 1250px;
  padding: 0px 15px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
}
.item_img {
  display: flex;
  flex: 1;
  padding: 30px 40px;
  align-items: center;
  justify-content: center;
}

.card_img {
  width: 100%;
  object-fit: cover;
}

.item_text_box {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    padding: 0px 20px;
  }
}

.item_text_box_reverse {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    padding: 0px 20px;
  }
}

.item_text_reverse {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  @media screen and (max-width: 1000px) {
    align-items: flex-start;
  }
}

.item_01 {
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
  }
}

.item_02,
.item_03 {
  font-size: 18px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
}

.about_bottom_wrap {
  display: flex;
  justify-content: center;
  background-color: #d1d1d11a;
  width: 100%;
  padding: 100px 0;
}
.about_bottom_container {
  padding: 40px;
}
.bottom_title_box {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.bottom_contents {
  display: flex;
  margin: 90px 0 0;
  gap: 20px;
  width: 100%;
  max-width: 1250px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 60px;
  }
}

.bottom_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.bottom_column_reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.bottom_content_text_box {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  background-color: #fff;
}

.bottom_text_title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  text-align: left;
}

.bottom_content_img_box {
  width: 100%;
  height: 300px;
}

.bottom_content_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* service */
.tap_Wrap {
  width: 100%;
  max-width: 1250px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  margin: 80px 0;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.tap_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tap_Buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.tap_Button {
  width: 100%;
  cursor: pointer;
  border: 1px solid;
  padding: 20px;
  font-size: 14px;
  text-align: center;
  border-color: #d9d9d9;
  background-color: white;
  color: black;
  @media screen and (max-width: 1000px) {
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
}

.selected_Button {
  border-color: #e50012;
  background-color: #e50012;
  color: white;
}

.service_title_img {
  display: flex;
  width: 100%;
  max-width: 1250px;
  height: 300px;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  margin-bottom: 50px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.service_title_box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 70px;
  margin: 100px 0px;
  max-width: 1250px;
  @media screen and (max-width: 1000px) {
    margin: 0px 30px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #e50012;
  }
}

.service_title {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: right;
  flex: 1;
  @media screen and (max-width: 1000px) {
    text-align: left;
  }
}
.service_table {
  display: flex;
  align-items: center;
  gap: 70px;
  padding-bottom: 100px;
  @media screen and (max-width: 1000px) {
    padding: 0px 30px;
    margin: 30px 0px;
  }
}

.service_table_title_box {
  display: flex;
  height: 280px;
  justify-content: flex-end;
  flex: 1;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.service_table_contents {
  flex: 3;
}

.service_table_item {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 30px 0;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.service_table_item_text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

hr {
  border-top: 1px solid #d9d9d9;
}

/* font 정리 */
.f48 {
  min-width: 48px;
  font-size: 48px;
  @media screen and (max-width: 1000px) {
    font-size: 24px;
  }
}

.f36 {
  font-size: 36px;
  @media screen and (max-width: 1000px) {
    font-size: 24px;
  }
}

.f30 {
  font-size: 30px;
  @media screen and (max-width: 1000px) {
    font-size: 20px;
  }
}

.f26 {
  font-size: 26px;
  @media screen and (max-width: 1000px) {
    font-size: 20px;
  }
}

.f24 {
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
}

.f22 {
  font-size: 22px;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
}

.f20 {
  font-size: 20px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
}

.f16 {
  font-size: 16px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
}

.red {
  color: #e50012;
}

.title {
  white-space: none;
  line-height: 3rem;

  @media screen and (max-width: 1000px) {
    line-height: 2rem;
    white-space: pre-wrap;
  }
}

.wide-text {
  letter-spacing: 4px;
}

.line {
  background-color: red;
  opacity: 0.2;
  height: 10px;
  width: 100%;
  margin-top: -10px;
  margin-bottom: -5px;

  @media screen and (max-width: 1000px) {
    height: 5px;
  }
}

.black {
  color: black;
}

.white {
  color: white;
}

.bold {
  /* font-weight: bolder; */
  font-weight: 900;
}

div {
  line-height: 1.6rem;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  font-family: "Noto Sans KR", sans-serif;

  @media screen and (max-width: 350px) {
    white-space: normal;
    word-break: normal;
    word-wrap: normal;
  }
}

.hr2 {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  max-width: 1250px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
