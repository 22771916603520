@keyframes fadeIn {
  from {
    opacity: 0; /* 투명도를 0으로 시작 */
  }
  to {
    opacity: 0.5; /* 투명도를 1로 끝냄 */
  }
}

.drawer {
    width: 80%;
    height: 100%;
    position: fixed;
    top: 0;
    left: -80%; /* Drawer가 초기에는 왼쪽으로 숨겨져 있습니다. */
    background-color: #ffffff;
    transition: left 0.3s ease-in-out;
  }

  /* 페이드 인 효과를 적용할 요소에 애니메이션 적용 */
  .drawer_background {
    animation: fadeIn 0.2s ease-in-out; /* 1초 동안 페이드 인 애니메이션 적용 */
  }
  .drawer.open {
    left: 0; /* Drawer가 열릴 때는 왼쪽으로 옮겨져 화면에 나타납니다. */
  }
  
  .drawer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .drawer ul li {
    padding: 10px;
    border-bottom: 1px solid rgb(246, 183, 183);
  }
  
  .headerButton {
    border: none;
    cursor: pointer;
    font-size: 18px;
    z-index: 9999;
  }