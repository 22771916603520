.desktopheader {
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.6);
}

.hstack {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0px 15px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1250px;
}

.desktop_logo {
  width: 300px;
}

.header_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_link {
  padding: 0px 20px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.mobileheader {
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
}
