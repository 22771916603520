.footer_wrap {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
}

.footer_bg_img {
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 350px;
  background-image: url("../../assets/about_background2.jpeg");
  z-index: 0;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer_bg_opacity {
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 350px;
  background-color: black;
  opacity: 0.7;
  z-index: 1;
}

.footer_title_box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 2;
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 350px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer_button {
  border: 1px solid white;
  padding: 10px 40px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    border: none;
    background-color: #e50012;
    font-size: 16px;
    padding: 8px 40px;
  }
}

.footer_info_bg {
  display: flex;
  width: 100%;
  align-items: flex-end;
  background-color: black;
  color: #777777;
  font-size: 14px;
  flex: 1;
}

.footer_info_container {
  width: 100%;
  padding: 60px 40px;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 15px;
  }
}

.pageup_button {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.footer_logo {
  width: 300px;
  margin-bottom: 30px;
}

.footer_info_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
}

.footer_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.footer_info_detail {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
}

.hr {
  width: 100%;
  border-bottom: 1px solid #777777;
}

.vr {
  width: 1px;
  border-right: 1px solid #777777;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.footer_title_text {
  font-size: 36px;
  color: white;
  @media screen and (max-width: 1000px) {
    font-size: 24px;
    flex-direction: column;
    align-items: center;
  }
}

.text_style {
  display: flex;
  flex-direction: row;
  gap: 8px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 0;
  }
}
